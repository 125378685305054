import {
  isValidEmail,
  isValidLicencePlate,
  isValidPhoneNumber,
} from '@wrisk/ui-components'
import { isNil, isObject, negate } from 'lodash'
import { DateTime } from 'luxon'
import { Validate } from 'react-hook-form'

import { Name } from '../../domain'
import { VehicleInputProps } from './inputs'

export const required = (isRequired: boolean) => (value: unknown) => {
  console.log(value)
  return (
    !isRequired ||
    (isObject(value) ? Object.values(value).every(negate(isNil)) : !isNil(value))
  )
}

const validationMap: Record<
  string,
  Record<string, (option?: unknown) => Validate<unknown>>
> = {
  name: {
    required,
    firstNameMinLength: (minLength: number) => (value: Name) =>
      value.firstName.length >= minLength,
    firstNameMaxLength: (maxLength: number) => (value: Name) =>
      value.firstName.length <= maxLength,
    lastNameMinLength: (minLength: number) => (value: Name) =>
      value.lastName.length >= minLength,
    lastNameMaxLength: (maxLength: number) => (value: Name) =>
      value.lastName.length <= maxLength,
  },
  email: {
    required,
    isValidEmail: () => (value: string) => isValidEmail(value),
  },
  text: {
    required,
    regExp: (expression: string) => (value: string) => RegExp(expression).test(value),
  },
  date: {
    required,
    maxYearsAgo: (maxYears: number) => (value: string) => {
      console.log(value)
      return (
        DateTime.local().startOf('day').diff(DateTime.fromISO(value), 'years').years <=
        maxYears
      )
    },
    noFutureDates: (allowed: boolean) => (value: string) => {
      console.log(value)
      return !allowed || DateTime.fromISO(value) < DateTime.local()
    },
  },
  yearMonth: {
    required,
    minYear: (minYear: number) => (value: string) =>
      DateTime.fromISO(value).year >= minYear,
  },
  numberText: {
    required,
    greaterThanOrEqual: (comparator: number) => (value: number) => value >= comparator,
    lessThanOrEqual: (comparator: number) => (value: number) => value <= comparator,
  },
  vehicleRegistration: {
    required: (isRequired: boolean) => (value: string) =>
      !isRequired || isValidLicencePlate(value),
  },
  phoneNumber: {
    required,
    format: (phoneNumberFormat: { prefix: string; regex: string }) => (value: string) =>
      isValidPhoneNumber(
        phoneNumberFormat.prefix,
        new RegExp(phoneNumberFormat.regex),
      )(value),
  },
  vehicleTabs: {
    required: () => (value: VehicleInputProps) =>
      !isNil(value.specification) && !isNil(value.regLookup),
  },
  checkbox: {
    required,
    onlyAccept: (accept: boolean) => (value: boolean | undefined) => value === accept,
  },
  checkboxPromotion: {
    required,
    onlyAccept: (accept: boolean) => (value: boolean | undefined) => value === accept,
  },
}

export const getValidationRules = (
  type: string,
  validation: Record<string, unknown>,
): Record<string, Validate<unknown>> => {
  const validationRules = validationMap[type] ?? {
    required,
  }

  return Object.entries(validation).reduce(
    (validation, [key, value]) =>
      validationRules[key]
        ? {
            ...validation,
            [key]: validationRules[key](value),
          }
        : validation,
    {},
  )
}
