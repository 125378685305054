import { Icon, mdBumps, Typo, WriskTheme } from '@wrisk/ui-components'

import logo from './assets/icons/logo.svg'
import mercedesSubscription from './assets/images/motor-insurance-hero.jpg'
import fontFaces from './fonts'
import icons from './icons'
import illus from './illus'

const colors = {
  white: '#FFFFFF',
  black: '#000000',
  primary700: '#014880',
  primary600: '#0078d6',
  primary50: '#E9F5FE',
  gray900: '#1a1a1a',
  gray850: '#262626',
  gray800: '#333333',
  gray700: '#4f4f4f',
  gray600: '#696969',
  gray500: '#848484',
  gray400: '#9f9f9f',
  gray300: '#bbbbbb',
  gray200: '#c9c9c9',
  gray150: '#dbdbdb',
  gray100: '#e8e8e8',
  gray75: '#f4f4f4',
  gray50: '#f8f8f8',
  alert700: '#ae1a1a',
  alert600: '#d92121',
  alert50: '#ffeded',
  success600: '#198025',
  success700: '#1b7b3a',
  success50: '#e8f2eb',
  warning600: '#facc00',
  warning50: '#fffae6',
}

const typoSizes = {
  xxl: {
    fontSize: ['32px', '34px', '40px', '48px'],
    fontWeight: 'normal',
    lineHeight: ['40px', '44px', '52px', '60px'],
  },
  xl: {
    fontFamily: `'MB Corpo A Title Cond', serif`,
    fontWeight: 'normal',
    fontSize: ['26px', '26px', '32px', '34px'],
    lineHeight: ['32px', '32px', '40px', '44px'],
  },
  lg: {
    fontSize: ['20px', '20px', '24px'],
    lineHeight: ['28px', '28px', '32px'],
  },
  md: {
    fontSize: ['18px', '18px', '20px'],
    lineHeight: ['28px', '28px', '28px'],
  },
  base: {
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '0.0125em',
  },
  sm: {
    fontSize: '14px',
    lineHeight: '20px',
    letterSpacing: '0.0125em',
  },
  xs: {
    fontSize: '12px',
    lineHeight: '16px',
    letterSpacing: '0.0125em',
  },
}

export const mercedesTheme: WriskTheme = {
  // Common
  name: 'Mercedes-Benz',
  illus,
  fontFaces,
  icons,
  logo: {
    regular: logo,
  },
  breakpoints: ['480px', '768px', '1024px', '1200px', '1600px'],
  space: [
    '0rem', //  0    0px
    '0.25rem', //  1    4px
    '0.5rem', //  2    8px
    '0.75rem', //  3   12px
    '1.0rem', //  4   16px
    '1.25rem', //  5   20px
    '1.5rem', //  6   24px
    '1.75rem', //  7   28px
    '2.0rem', //  8   32px
    '2.5rem', //  9   40px
    '3.0rem', // 10   48px
    '3.5rem', // 11   56px
    '4.0rem', // 12   64px
    '4.5rem', // 13   72px
    '5.0rem', // 14   80px
  ],
  radii: ['0rem', '0rem', '0rem', '0rem', '100vh'],
  lineHeights: ['1em', '1.1em', '1.2em', '1.3em', '1.4em', '1.5em'],
  fonts: {
    body: `'MB Corpo S Text', sans-serif`,
    header: `'MB Corpo S Text', serif`,
    navbar: `'MB Corpo S Text', sans-serif`,
    buttons: `'MB Corpo S Text', sans-serif`,
    input: `'MB Corpo S Text', sans-serif`,
    licencePlate: `'UKLicencePlate', sans-serif`,
  },
  typoSizes,
  shadows: {
    default: `0 0 0 1px ${colors.gray200}`,
    hover: `0 0 0 1px ${colors.gray400}`,
    input: `0 0 0 2px ${colors.primary600}`,
    inset: `inset -8px 0 0 0 ${colors.white}, inset 8px 0 0 0 ${colors.white}`,
    popupMenu: '0 4px 32px 0 rgba(0, 0, 0, 0.06)',
    radioNested: `inset 0 0 0 1px ${colors.gray200}`,
    radioStandalone: `inset 0 0 0 1px ${colors.gray200}`,
    radioHover: `inset 0 0 0 1px ${colors.gray900}`,
    radioChecked: `inset 0 0 0 1px ${colors.gray900}`,
  },
  transitions: {
    default: 'all 250ms ease',
  },
  navigation: {
    height: ['84px'],
  },
  colors: {
    chalk: colors.white,
    background: colors.white,
    body: colors.gray800,
    bodySecondary: colors.gray600,
    hyperlink: colors.gray800,
    focus: colors.primary600,
    hover: colors.gray75,

    primaryAccent: colors.primary600,
    secondaryAccent: colors.primary600,

    foregroundPromoBanner: colors.white,
    backgroundPromoBanner: colors.gray900,

    foregroundPromoActivated: colors.white,
    backgroundPromoActivated: colors.gray900,

    textOnNavigation: colors.white,
    surfaceNavigation: colors.black,

    textInfo: colors.primary600,
    surfaceInfoSubdued: colors.primary50,
    surfaceInfo: colors.primary600,

    textCritical: colors.alert600,
    textOnCritical: colors.alert600,
    surfaceCritical: colors.alert600,
    surfaceCriticalHovered: colors.alert50,
    surfaceCriticalSubdued: colors.alert50,

    textWarning: colors.warning600,
    textOnWarning: colors.warning600,
    surfaceWarning: colors.warning600,
    surfaceWarningSubdued: colors.warning50,

    textHighlight: colors.gray600,
    textOnHighlight: colors.gray600,
    surfaceHighlight: colors.gray75,
    surfaceHighlightSubdued: colors.gray75,

    textSuccess: colors.success700,
    textOnSuccess: colors.success700,
    surfaceSuccess: colors.success600,
    surfaceSuccessSubdued: colors.success50,

    border: colors.gray100,
    divider: colors.gray100,
    inactive: colors.gray600,
    placeholder: colors.gray500,

    textDisabled: colors.gray600,
    surfaceDisabled: colors.gray100,

    textFooter: colors.gray300,
    dividerFooter: colors.gray600,
    surfaceFooter: colors.black,

    radioCheckedBackground: colors.gray900,
    radioCheckedForeground: colors.white,

    dragBoxBorderActive: colors.primary600,
    dragBoxBorderDefault: colors.gray300,

    progressBackgroundColor: colors.gray100,
    progressBarColor: colors.gray600,
  },
  proposalImages: {
    'mercedes-subscription': mercedesSubscription as string,
  },
  containerStyle: {
    default: {},
    raised: {
      backgroundColor: colors.white,
      borderRadius: 0,
      borderStyle: 'solid',
      borderColor: 'border',
      borderWidth: '1px',
    },
    prominent: {},
  },
  // Components
  Logo: {
    default: {
      height: ['40px'],
    },
  },
  Button: {
    base: {
      transition: 'all 300ms ease-in-out',
      borderRadius: '2px',
    },
    layout: {
      default: {
        px: 6,
        py: 3,
        fontSize: '16px',
        lineHeight: '24px',
      },
      fixed: {
        px: 6,
        py: 3,
        fontSize: '16px',
        lineHeight: '24px',
        width: '85%',
        maxWidth: '262px',
      },
      small: {
        px: 4,
        py: 2,
        fontSize: '14px',
        lineHeight: '20px',
      },
    },
  },
  PrimaryButton: {
    disabled: {
      default: {
        opacity: '0.4',
      },
    },
    variants: {
      default: {
        bg: colors.primary600,
        color: colors.white,
        borderColor: colors.primary600,
        borderWidth: '1px',
        borderStyle: 'solid',
        '&:hover:not([disabled])': {
          boxShadow: 'none',
          bg: colors.primary700,
        },
      },
      inverted: {
        bg: colors.primary600,
        color: colors.white,
        borderColor: colors.primary600,
        borderWidth: '1px',
        borderStyle: 'solid',
        '&:hover:not([disabled])': {
          boxShadow: 'none',
          bg: colors.primary700,
        },
      },
      critical: {
        bg: colors.alert600,
        color: colors.white,
        borderColor: colors.alert600,
        borderWidth: '1px',
        borderStyle: 'solid',
        '&:hover:not([disabled])': {
          boxShadow: 'none',
          bg: colors.alert700,
        },
      },
    },
  },
  SecondaryButton: {
    disabled: {
      default: {
        opacity: '0.4',
      },
    },
    variants: {
      default: {
        boxShadow: `inset 0 0 0 1px ${colors.gray300}`,
        color: colors.gray800,
        '&:hover:not([disabled])': {
          bg: colors.gray100,
        },
      },
      inverted: {
        boxShadow: `inset 0 0 0 1px ${colors.gray600}`,
        color: colors.white,
        '&:hover:not([disabled])': {
          bg: 'hsla(0, 0%, 100%, .16)',
        },
      },
    },
  },
  TertiaryButton: {
    disabled: {
      default: {
        opacity: '0.4',
      },
    },
    variants: {
      default: {
        boxShadow: `inset 0 0 0 1px ${colors.gray300}`,
        color: colors.gray800,
        '&:hover:not([disabled])': {
          bg: colors.gray100,
        },
      },
      inverted: {
        boxShadow: `inset 0 0 0 1px ${colors.gray600}`,
        color: colors.white,
        '&:hover:not([disabled])': {
          bg: 'hsla(0, 0%, 100%, .16)',
        },
      },
    },
  },
  IconButton: {
    disabled: {
      default: {
        opacity: '0.4',
      },
    },
    variants: {
      default: {
        '&:hover:not([disabled])': {
          bg: colors.gray50,
        },
      },
      primary: {
        bg: colors.primary600,
        [Icon]: {
          'path, ellipse': {
            fill: '#FFF',
          },
        },
        '&:hover:not([disabled])': {
          boxShadow: 'none',
          bg: colors.gray900,
        },
      },
    },
  },
  Heading: {
    default: {},
    h1: {
      fontFamily: `'MB Corpo A Title Cond', serif`,
      fontWeight: 'normal',
    },
    h2: {
      fontFamily: `'MB Corpo A Title Cond', serif`,
      fontWeight: 'normal',
    },
    h3: {
      fontWeight: 'bold',
    },
    h4: {
      fontWeight: 'bold',
    },
  },
  TabBar: {
    default: {
      borderBottomWidth: 1,
      borderColor: colors.gray100,
    },
  },
  TabAnchor: {
    active: {
      mr: '8px !important',
      py: 3,
      px: 4,
      color: colors.gray800,
      borderColor: colors.primary600,
      borderBottomWidth: 2,
      '&:hover': {
        backgroundColor: colors.gray75,
      },
    },
    inactive: {
      mr: '8px !important',
      py: 3,
      px: 4,
      color: colors.gray500,
      borderColor: 'transparent',
      borderBottomWidth: 2,
      '&:hover': {
        backgroundColor: colors.gray75,
      },
    },
  },
  LinkAnchor: {
    inline: {
      color: colors.primary600,
      fontWeight: 'normal',
      borderBlockEnd: '.0625rem solid currentColor;',
      '&:hover': {
        color: colors.primary700,
      },
    },
    standalone: {
      color: colors.gray800,
      border: 'none',
      paddingBlockEnd: '.125rem',
      transition: 'background-size .42s cubic-bezier(.165,.84,.44,1) 0s',
      backgroundSize: '0 100%, 100% 100%',
      backgroundPositionX: '0%',
      backgroundRepeat: 'no-repeat, no-repeat',
      backgroundImage:
        'linear-gradient(to top, rgb(0, 120, 214) 2px, rgba(0, 0, 0, 0) 2px), linear-gradient(to top, rgb(187, 187, 187) 1px, rgba(0, 0, 0, 0) 1px)',
      backgroundColor: 'transparent',
      '&:hover': {
        backgroundSize: '100% 100%, 100% 100%',
      },
    },
  },
  FooterAnchor: {
    default: {
      paddingBottom: '2px',
      transition: 'background-size .42s cubic-bezier(.165,.84,.44,1)',
      backgroundSize: '0 100%, 100% 100%',
      backgroundPositionX: 'left',
      backgroundRepeat: 'no-repeat, no-repeat',
      backgroundImage:
        'linear-gradient(to top, rgb(255, 255, 255) 1px, rgba(0, 0, 0, 0) 1px)',
      backgroundColor: 'transparent',
      color: colors.gray300,
      '&:hover': {
        color: colors.white,
        backgroundSize: '100% 100%, 100% 100%',
      },
    },
  },
  NavBar: {
    default: {
      pl: [6, 6, 9],
      pr: [6, 6, 9],
    },
  },
  NavAnchor: {
    default: {
      borderRadius: 0,
      ':hover': {
        backgroundColor: colors.gray850,
      },
      [Icon]: {
        path: {
          fill: colors.white,
        },
      },
    },
    inverted: {
      fontWeight: 'bold',
    },
  },
  TextInput: {
    default: {
      boxShadow: 'default',
      p: mdBumps,
      bg: colors.gray50,
      '&:focus': {
        boxShadow: 'input',
        bg: colors.white,
      },
      '&:hover': {
        bg: colors.white,
      },
    },
    ghost: {
      boxShadow: 'none',
      bg: 'transparent',
      py: mdBumps,
      px: 0,
    },
    licencePlate: {
      fontFamily: 'licencePlate',
      bg: 'transparent',
      boxShadow: 'none',
      py: mdBumps,
      px: 0,
    },
  },
  InputWrapper: {
    default: {
      bg: colors.gray50,
      px: mdBumps,
      boxShadow: 'default',
      '&:focus-within': {
        boxShadow: 'input',
      },
      '&:hover': {
        bg: colors.white,
      },
      '&:focus': {
        bg: colors.white,
      },
    },
    ghost: {
      px: 0,
      boxShadow: 'none',
    },
  },
  StyledPremium: {
    default: {},
    inverted: {
      bg: 'transparent',
      color: colors.gray800,
    },
  },
  Status: {
    default: {
      bg: 'surfaceHighlightSubdued',
      color: 'textHighlight',
      borderRadius: 4,
    },
    info: {
      bg: 'surfaceInfoSubdued',
      color: 'textInfo',
      borderRadius: 4,
    },
    success: {
      bg: 'surfaceSuccessSubdued',
      color: 'textSuccess',
      borderRadius: 4,
    },
    warning: {
      bg: 'surfaceWarningSubdued',
      color: 'textWarning',
      borderRadius: 4,
    },
    critical: {
      bg: 'surfaceCriticalSubdued',
      color: 'textCritical',
      borderRadius: 4,
    },
  },
  CollapsibleSectionContent: {
    default: {
      px: mdBumps,
      backgroundColor: colors.gray75,
    },
  },
  CollapsibleSectionHeader: {
    default: {
      p: mdBumps,
      '&:active, &:hover': {
        backgroundColor: colors.gray75,
        [Icon]: {
          backgroundColor: colors.white,
        },
      },
      [Typo]: {
        fontWeight: 'bold',
      },
      [Icon]: {
        size: '36px',
        borderRadius: '100%',
        backgroundColor: colors.gray75,
        p: 2,
      },
    },
    visible: {
      p: mdBumps,
      borderBottomColor: colors.gray75,
      backgroundColor: colors.gray75,
      [Typo]: {
        fontWeight: 'bold',
      },
      [Icon]: {
        size: '36px',
        borderRadius: '100%',
        backgroundColor: colors.white,
        p: 2,
      },
    },
  },
}
