import css from '@styled-system/css'
import styled from 'styled-components'
import { color, ColorProps, compose, space, SpaceProps, variant } from 'styled-system'

import { xxlBumps } from '../layout'

export type PageContainerProperty = 'default'

export interface PageContainerProps extends ColorProps, SpaceProps {
  variant?: PageContainerProperty
}

export const PageContainer = styled.section<PageContainerProps>(
  css({
    flex: '1 0 auto',
    pb: xxlBumps,
  }),
  variant({
    scale: 'PageContainer',
    variants: {
      default: {},
    },
  }),
  compose(color, space),
)

PageContainer.defaultProps = {
  variant: 'default',
}
