import css from '@styled-system/css'
import styled from 'styled-components'
import {
  compose,
  flexbox,
  FlexboxProps,
  layout,
  LayoutProps,
  space,
  SpaceProps,
  variant,
} from 'styled-system'

import { mainContainerWidths } from './containerWidths'

export type MainSectionProperty = 'default'

export interface MainSectionProps extends FlexboxProps, LayoutProps, SpaceProps {
  variant?: MainSectionProperty
}

export const MainSection = styled.div<MainSectionProps>(
  css({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    width: mainContainerWidths,
    minWidth: mainContainerWidths,
    mx: 'auto',
  }),
  variant({
    scale: 'MainSection',
    variants: {
      default: {},
    },
  }),
  compose(flexbox, space, layout),
)

MainSection.defaultProps = {
  variant: 'default',
}
