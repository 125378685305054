import {
  AccountNavBox,
  MenuBox,
  MenuItem,
  NavAnchor,
  NavBar,
  Navigation,
  PopupMenu,
} from '@wrisk/ui-components'
import React, { FunctionComponent, useCallback, useState } from 'react'

import { usePrincipal } from '../../application/authentication'
import { tDefaults, useWriskTranslation } from '../../infrastructure/internationalisation'
import { useDependencies } from '../../state'
import { AppNameBox } from './AppNameBox'
import { LogoNavBox } from './LogoNavBox'
import { MainMenu } from './MainMenu'

export interface UserNavProps {
  accountNavEnabled: boolean
}

export const UserNav: FunctionComponent<UserNavProps> = ({ accountNavEnabled }) => {
  const { t } = useWriskTranslation()

  const [menuVisible, setMenuVisible] = useState(false)
  const onToggleMenu = useCallback(() => setMenuVisible((visible) => !visible), [])

  const principal = usePrincipal()

  const { chatClient } = useDependencies()

  return (
    <Navigation>
      <NavBar>
        <LogoNavBox />
        <AppNameBox />
        <MenuBox>
          <NavAnchor id='helpNavAction' onClick={chatClient.show}>
            <MenuItem text={t<string>(tDefaults('actions.help'))} />
          </NavAnchor>
          {accountNavEnabled && principal.isAuthenticated && (
            <>
              <AccountNavBox
                user={principal.user}
                onClick={onToggleMenu}
                active={menuVisible}
              />
              <PopupMenu visible={menuVisible} onDismiss={onToggleMenu}>
                <MainMenu user={principal.user} onDismiss={onToggleMenu} />
              </PopupMenu>
            </>
          )}
        </MenuBox>
      </NavBar>
    </Navigation>
  )
}
